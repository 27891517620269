import { FetchResult } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { Error, ResponsePayloadWithError } from '../generated/graphql';
import { getErrorMessage } from './get-error-message';

type GraphQLDataWithError = {
  [key: string]: string | null | ResponsePayloadWithError;
};

export function extractErrorMessage<T extends GraphQLDataWithError>(
  rsp: FetchResult<T>,
  key: keyof T,
): string | null {
  const { data, errors } = rsp;
  if (!data || !data[key]) {
    return null;
  }
  const payload = data[key] as ResponsePayloadWithError;
  if (payload.error && payload.error.message) {
    return payload.error.message;
  } else if (errors?.length) {
    return 'Something went wrong. Please try again';
  }
  return null;
}

type Payload<T extends string> =
  | {
      data?: Partial<Record<T, any>> | null;
      errors?: readonly GraphQLError[] | null;
    }
  | null
  | undefined;

export async function extractGraphQLErrorIfExists<T extends string>(
  prms: Payload<T> | Promise<Payload<T>>,
  access: T,
): Promise<{ error?: string }> {
  let rsp: Payload<T>;
  try {
    rsp = await prms;
  } catch (error) {
    return { error: getErrorMessage(error) };
  }

  if (!rsp) return {};
  const potentialError = rsp.data
    ? rsp.data[access].error
      ? (rsp.data[access].error as Error | { __typename?: string })
      : (rsp.data[access] as Error | { __typename?: string })
    : undefined;
  const err = getUserFacingError(potentialError);
  if (err) {
    return { error: err.message };
  }
  if (rsp.errors?.length) {
    const err = rsp.errors[0];
    return { error: err.message };
  }

  return {};
}

export function getUserFacingError(
  obj: Error | { __typename?: string } | null | undefined,
) {
  if (!obj) return null;
  if (isError(obj)) {
    return obj;
  }
  return null;
}

function isError(obj: Error | { __typename?: string }): obj is Error {
  return obj.__typename === 'Error';
}
