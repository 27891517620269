import { BroadcastMessages } from '@/components/broadcast-messages/BroadcastMessages';
import { SectionButton } from '@/components/button/SectionButton';
import { Icons } from '@/components/navbar/Icons';
import {
  SHIPMENT_GROUPINGS,
  ShipmentGrouping,
} from '@/components/shipments/shipment-groupings';
import { groupBy } from '@/utilities/lib';
import { pluralize } from '@/utilities/pluralize';
import { ImportableShipmentsBanner } from '@components/inbox/ImportableShipmentsBanner';
import { formatFriendlyTimezone } from '@packfleet/datetime';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { IoAdd, IoRepeat } from 'react-icons/io5';
import slugify from 'slugify';
import {
  CollectionFragment,
  CollectionLiveTrackingFragment,
  CollectionLocationFragment,
  ShipmentFragment,
} from '../../../generated/graphql';
import {
  useDeviceTimezone,
  useOrganizationTimezone,
} from '../../../hooks/timezone';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { Routes, route } from '../../../utilities/routes';
import LinkButton from '../../button/LinkButton';
import CollectionLocationsSelector from '../../collections/CollectionLocationsSelector';
import CollectionsCalendar from '../../collections/CollectionsCalendar';
import Container from '../../container/Container';
import Heading from '../../heading/Heading';
import Page from '../../page/Page';
import Sidebar from '../../sidebar/Sidebar';

type Props = {
  locationId?: string;
  shipments: ShipmentFragment[];
  hasAnyShipments: boolean;
  collections: CollectionFragment[];
  collectionLocations: CollectionLocationFragment[];
  collectionTrackingInfos: CollectionLiveTrackingFragment[];
};

function DashboardPage({
  locationId,
  shipments,
  collections,
  collectionLocations,
  collectionTrackingInfos,
  hasAnyShipments,
}: Props) {
  const router = useRouter();
  const user = useCurrentUser();
  const orgTimezone = useOrganizationTimezone();
  const deviceTimezone = useDeviceTimezone();
  const [selectedLocation, setSelectedLocation] =
    useState<CollectionLocationFragment | null>(null);

  const filteredShipments = shipments.filter(
    (s) =>
      selectedLocation == null ||
      selectedLocation.id === s.collectionLocation.id,
  );

  const shipmentsByGrouping = groupBy(filteredShipments, (i) => {
    return (
      Object.keys(SHIPMENT_GROUPINGS).find((k) => {
        return SHIPMENT_GROUPINGS[k as ShipmentGrouping].includes(i.status);
      }) ?? ''
    );
  });

  const filteredCollections = collections.filter(
    (s) => selectedLocation == null || selectedLocation.id === s.location.id,
  );

  const hasMultipleLocations =
    collectionLocations && collectionLocations.length > 1;

  useEffect(() => {
    setSelectedLocation(
      collectionLocations.find((l) => locationId === l.id) ?? null,
    );
  }, [collectionLocations, locationId]);

  const handleSelectedLocationsChange = async (
    selectedLocation: CollectionLocationFragment | null,
  ) => {
    if (selectedLocation != null) {
      setSelectedLocation(selectedLocation);

      const slug = slugify(
        selectedLocation.name ??
          `${selectedLocation.address.line1}-${selectedLocation.address.postCode}`,
        { lower: true },
      );

      await router.push(
        route(Routes.appLocationDashboard, {
          id: selectedLocation.id,
          slug: slug,
        }),
      );
    } else {
      setSelectedLocation(null);
      await router.push(route(Routes.appDashboard));
    }
  };

  if (!user || !user.organization) {
    return null;
  }

  return (
    <Page
      title="Packfleet"
      fullHeight={true}
      sidebar={<Sidebar activeTitle="Home" />}
      className="bg-secondary"
      intercomEnabled={true}
    >
      <Container>
        <BroadcastMessages />
        <ImportableShipmentsBanner />
        <div className="relative">
          <a id="collections" className="absolute -top-20 md:-top-4" />
          {orgTimezone !== deviceTimezone ? (
            <div className="mb-4 flex items-center justify-center rounded bg-warningLight p-2 text-center text-warning">
              <IoIosInformationCircleOutline className="mr-2 text-2xl" />
              <span>
                {deviceTimezone
                  ? `It looks like youʼre in ${formatFriendlyTimezone(
                      deviceTimezone,
                    )}.`
                  : null}
                Your Packfleet dashboard will still appear in{' '}
                {formatFriendlyTimezone(orgTimezone)}
              </span>
            </div>
          ) : null}
          <Heading level={2} headingStyle="heading1">
            Collections
          </Heading>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex sm:flex-1 sm:items-center">
              <LinkButton
                color="brand"
                mode="outline"
                s="small"
                className="mt-3 w-full sm:mr-2 sm:w-auto"
                icon={IoAdd}
                href={route(Routes.appNewCollection)}
              >
                Book a collection
              </LinkButton>
              {user.organization.returnCollectionsAllowed ? (
                <LinkButton
                  color="brand"
                  mode="outline"
                  s="small"
                  className="mt-3 w-full sm:mr-2 sm:w-auto"
                  icon={IoAdd}
                  href={route(Routes.appNewReturn)}
                >
                  Book a return
                </LinkButton>
              ) : null}
              <LinkButton
                color="brand"
                mode="outline"
                s="small"
                className="mt-3 w-full sm:mr-2 sm:w-auto"
                icon={IoRepeat}
                href={route(Routes.appManageRecurringCollection)}
              >
                Manage recurring collections
              </LinkButton>
            </div>
            <div className="sm:justify mt-3 w-full sm:w-auto md:flex">
              <div>
                <LinkButton
                  color="brand"
                  mode="outline"
                  s="small"
                  className="mb-3 w-full sm:mr-2 sm:w-auto md:ml-3 md:mb-0"
                  href={route(Routes.appManageCollectionLocations)}
                >
                  Manage locations
                </LinkButton>
              </div>
              {hasMultipleLocations && (
                <CollectionLocationsSelector
                  locations={collectionLocations}
                  selectedLocation={selectedLocation}
                  onChange={handleSelectedLocationsChange}
                />
              )}
            </div>
          </div>
          {collections.length > 0 ? (
            <div className="mt-4 w-full">
              <CollectionsCalendar
                organization={user.organization}
                collections={filteredCollections}
                liveTracking={collectionTrackingInfos}
              />
            </div>
          ) : null}
        </div>
        <div className="relative mt-6">
          <div>
            <>
              <a id="shipments" className="absolute -top-20 md:-top-4" />
              <Heading level={2} headingStyle="heading1" className="mb-3">
                Shipments
              </Heading>
              <div className="sm:flex sm:items-center">
                <div className="flex flex-wrap gap-4 sm:flex-1 sm:items-center">
                  {hasAnyShipments ? (
                    <>
                      <SectionButton
                        href={route(Routes.appShipments)}
                        icon={Icons.add}
                        title="Add shipments"
                        description="Manually, or via CSV, Shopify, etc."
                      />
                      {Object.keys(SHIPMENT_GROUPINGS).map((grouping) => {
                        let icon;
                        let title = grouping;
                        let href = '';

                        switch (grouping as ShipmentGrouping) {
                          case ShipmentGrouping.toCollect:
                            icon = Icons.collectionsInverted;
                            title = 'Ready to collect and print labels';
                            href = route(Routes.appShipmentsToCollect);
                            break;
                          case ShipmentGrouping.onTheirWay:
                            icon = Icons.onTheirWayInverted;
                            href = route(Routes.appShipmentsOnTheirWay);
                            break;
                          case ShipmentGrouping.delivered:
                            icon = Icons.deliveredInverted;
                            href = route(Routes.appShipmentsDelivered);
                            break;
                        }

                        // Exclude "failed" for now
                        if (grouping === ShipmentGrouping.failed) {
                          return null;
                        }

                        const numShipments =
                          shipmentsByGrouping[grouping]?.length ?? 0;

                        return (
                          <SectionButton
                            key={href}
                            href={href}
                            icon={
                              <div className="overflow-hidden rounded">
                                {icon}
                              </div>
                            }
                            title={title}
                            description={
                              numShipments > 0
                                ? numShipments +
                                  ' ' +
                                  pluralize(
                                    numShipments,
                                    'shipment',
                                    'shipments',
                                  )
                                : 'No shipments yet'
                            }
                          />
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            </>
            {hasAnyShipments ? null : (
              <div className="mb-4 rounded border border-primary bg-primary shadow-sm">
                <div className="w-100 flex flex-col items-center justify-center p-4 py-26">
                  <Heading level={3} headingStyle="heading2" className="mb-6">
                    Ready to send your first shipment?
                  </Heading>
                  <LinkButton href={route(Routes.appShipments)}>
                    Add shipments
                  </LinkButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Page>
  );
}
export default DashboardPage;
