import { extractErrorMessage } from '@/errors/utilities';
import { FetchResult } from '@apollo/client';
import { isToday, parsePlainDate } from '@packfleet/datetime';
import { Button } from '@packfleet/ui';
import cs from 'classnames';
import React, { useState } from 'react';
import {
  CollectionWithShipmentCountsFragment,
  SkipCollectionInput,
  SkipCollectionMutation,
  UnskipCollectionMutation,
  useCheckCollectionCancellationTermsLazyQuery,
} from '../../generated/graphql';
import { useOrganizationTimezone } from '../../hooks/timezone';
import { formatCollectionLocation } from '../../utilities/collection-locations';
import { formatCollection } from '../../utilities/collections';
import { pluralize } from '../../utilities/pluralize';
import { Routes, route } from '../../utilities/routes';
import { formatStartEndTime } from '../../utilities/time';
import LinkButton from '../button/LinkButton';
import Heading from '../heading/Heading';
import WithAlert from '../modal/WithAlert';
import Tooltip from '../tooltip/Tooltip';
import { CollectionTerms } from './CollectionTerms';

export type Props = {
  collection: CollectionWithShipmentCountsFragment;
  onSkipCollection: (
    input: SkipCollectionInput,
  ) => Promise<FetchResult<SkipCollectionMutation>>;
  onUnskipCollection: (
    id: string,
  ) => Promise<FetchResult<UnskipCollectionMutation>>;
  isNext?: boolean;
};

const CollectionCard = ({
  collection,
  onSkipCollection,
  onUnskipCollection,
  isNext,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const timezone = useOrganizationTimezone();
  const [checkCollectionCancellationTerms, { data, error }] =
    useCheckCollectionCancellationTermsLazyQuery({
      variables: { input: { collectionId: collection.id } },
      fetchPolicy: 'network-only',
    });

  const numShipments = collection.numShipments ?? 0;
  const numPacks = collection.numPacks ?? 0;
  const numExternalShipments = collection.numExternalShipments ?? 0;
  const numExternalPacks = collection.numExternalPacks ?? 0;
  const totalShipmentCount = numShipments + numExternalShipments;
  const totalPackCount = numPacks + numExternalPacks;
  const allShipmentsCollected = collection.allShipmentsCollected;
  const checkTermsError =
    error?.message || data?.checkCollectionCancellationTerms?.error?.message;
  const terms = data?.checkCollectionCancellationTerms?.terms;

  const title = formatCollection(collection, timezone);
  const isSkipped = collection.skipped;
  const isRecurring = collection.recurringCollectionId != null;
  const { isReturnLocation } = collection.location;

  return (
    <div
      className={cs(
        'relative flex h-full items-start rounded border border-primary p-4',
        {
          'bg-secondary text-secondary': isSkipped,
          'bg-primary shadow-md': !isSkipped,
        },
      )}
      key={collection.id}
    >
      <div className="flex-1">
        <div>
          {isSkipped ? (
            <Heading level={2} className="mb-2">
              Skipped collection
            </Heading>
          ) : isNext ? (
            <Heading level={2} className="mb-2">
              Next collection
            </Heading>
          ) : null}
        </div>
        <strong
          className={cs({
            'text-success': title === 'Today' || title === 'Tomorrow',
          })}
        >
          {title}
        </strong>
        <div>
          <strong>
            {formatStartEndTime(collection.startTime, collection.endTime)}
          </strong>
        </div>
        <div>
          {isReturnLocation ? 'A return' : null} from{' '}
          {formatCollectionLocation(collection.location)}
        </div>
        {!isSkipped ? (
          <div
            className={cs('mt-3 font-medium', {
              'text-warning': totalShipmentCount === 0,
            })}
          >
            {isReturnLocation
              ? null
              : totalShipmentCount === 0
                ? 'No shipments yet'
                : allShipmentsCollected
                  ? `All shipments collected`
                  : `${totalShipmentCount} ${pluralize(
                      totalShipmentCount,
                      'shipment',
                      'shipments',
                    )}
                ${
                  totalPackCount !== totalShipmentCount
                    ? ` (${totalPackCount} ${pluralize(
                        totalPackCount,
                        'pack',
                        'packs',
                      )})`
                    : ''
                } to collect`}
          </div>
        ) : null}
        <div className="mt-2">
          {errorMessage && <span className="text-danger">{errorMessage}</span>}
        </div>
      </div>
      {isRecurring &&
      isSkipped &&
      !isToday(parsePlainDate(collection.date), timezone) ? (
        <WithAlert
          button={
            <Button s="tiny" color="neutral" mode="outline" className="ml-1">
              Un-skip
            </Button>
          }
          title="Un-skip this collection?"
          body={`Weʼll come to collect ${formatCollection(
            collection,
            timezone,
            true,
          )}`}
          confirmText={'Yes, un-skip'}
          onConfirm={async () => {
            const result = await onUnskipCollection(collection.id);
            const errorMsg = extractErrorMessage(result, 'unskipCollection');
            if (errorMsg) {
              setErrorMessage(errorMsg);
            } else {
              setErrorMessage('');
            }
          }}
          cancelText="Cancel"
          onCancel={() => null}
        />
      ) : isRecurring && !isSkipped ? (
        <>
          <LinkButton
            s="tiny"
            color="neutral"
            className="ml-4"
            mode="outline"
            href={route(Routes.appManageRecurringCollection, {
              location: collection.location.id,
            })}
          >
            Manage
          </LinkButton>
          {totalShipmentCount === 0 ? (
            <WithAlert
              button={
                <Button
                  s="tiny"
                  color="neutral"
                  mode="outline"
                  className="ml-1"
                >
                  Skip
                </Button>
              }
              onOpen={async () => {
                await checkCollectionCancellationTerms();
              }}
              title="Skip this collection?"
              body={
                <div>
                  <p className="mb-2">
                    Weʼll no longer come to collect{' '}
                    {formatCollection(collection, timezone, true)}
                  </p>
                  {checkTermsError ? (
                    <p className="text-danger mb-2">
                      Something went wrong checking the cancelation conditions
                    </p>
                  ) : null}
                  <CollectionTerms terms={terms} tz={timezone} />
                </div>
              }
              confirmText={'Yes, Skip'}
              onConfirm={async () => {
                const result = await onSkipCollection({
                  collectionId: collection.id,
                  feeAccepted: terms?.cancellationFee
                    ? {
                        amount: terms.cancellationFee.amount,
                        currencyCode: terms.cancellationFee.currencyCode,
                      }
                    : null,
                });
                const errorMsg = extractErrorMessage(result, 'skipCollection');
                if (errorMsg) {
                  setErrorMessage(errorMsg);
                } else {
                  setErrorMessage('');
                }
              }}
              cancelText="Cancel"
              onCancel={() => null}
            />
          ) : (
            <Tooltip title="Canʼt skip as there are shipments to be collected">
              <Button
                s="tiny"
                color="neutral"
                mode="outline"
                className="ml-1"
                disabled
              >
                Skip
              </Button>
            </Tooltip>
          )}
        </>
      ) : !isRecurring ? (
        <LinkButton
          s="tiny"
          color="neutral"
          mode="outline"
          href={route(
            isReturnLocation ? Routes.appEditReturn : Routes.appEditCollection,
            {
              id: collection.id,
            },
          )}
        >
          Edit
        </LinkButton>
      ) : null}
    </div>
  );
};

export default CollectionCard;
